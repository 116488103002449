import {Link} from 'gatsby'

import React from 'react'

import { getPageUrl } from '../lib/helpers'

import * as styles from './breadcrumb.module.css'

function Breadcrumb (props) {
  return (
    <div className={styles.breadcrumb}>
        <span>
            <Link className={styles.breadcrumb_link} to="/">{props.home}</Link>
            { // show site_section as long as no parent
              !props.thisPage.parent?.slug && props.thisPage.site_section?.slug && (<> {props.separator} <Link className={styles.breadcrumb_link} to={`/${props.thisPage.site_section?.slug}/`}>{props.thisPage.site_section?.section}</Link> </>)}
            { // show grandparent 
              props.thisPage.parent?.include_parent_when_in_breadcrumb_as_parent && (<> {props.separator} <Link className={styles.breadcrumb_link} to={`/${props.thisPage.parent.parent.slug}/`}>{props.thisPage.parent.parent.title_short}</Link> </>)
            }
            { props.thisPage.parent?.slug && (<> {props.separator} <Link className={styles.breadcrumb_link} to={`/${props.thisPage.parent?.slug}/`}>{props.thisPage.parent.title_short ? props.thisPage.parent.title_short : props.thisPage.parent?.title}</Link> </>)}
            { props.thisPage?.title_short && (<> {props.separator} {props.thisPage?.title_short}</>)}
        </span>
    </div>
  )
}

Breadcrumb.defaultProps = {
  home: 'Home',
  thisPage: '',
  separator: ">"
}

export default Breadcrumb
